
































































import {
  InlineLoader,
  PatientDetailsResponsesSortCol,
  SortDir,
  StatusIndicatorIcon,
} from '@/shared';
import sink from '@/sink';
import { computed, defineComponent, onMounted } from '@vue/composition-api';
import {
  PatientDetailsResponsesFetched,
  PatientDetailsResponsesFiltersCleared,
  PatientDetailsResponsesTablePageUpdated,
  PatientDetailsResponsesTableSorted,
  PatientDetailsResponsesFilteredFetched,
} from './+state/events';

export default defineComponent({
  components: {
    InlineLoader,
    StatusIndicatorIcon,
  },
  setup() {
    onMounted(() => sink.broadcast(PatientDetailsResponsesFetched()));

    const sortCol = sink.select('patient.details.responses.sort-col');
    const sortDir = sink.select('patient.details.responses.sort-dir');

    const updateSort = (sortInfo: {
      col: PatientDetailsResponsesSortCol;
      dir: SortDir;
    }) => sink.broadcast(PatientDetailsResponsesTableSorted(sortInfo));

    const onChangeSortColumn = (col: PatientDetailsResponsesSortCol) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    const hasFilters = computed(
      () =>
        !!Object.keys(sink.select('patient.details.responses.filters').value)
          .length,
    );

    return {
      onChangeSortDirection,
      onChangeSortColumn,
      updatePage: (page: number) =>
        sink.broadcast(PatientDetailsResponsesTablePageUpdated({ page })),
      headerInfo: sink.select('patients.detail.header'),
      sortCol,
      sortDir,
      loading: sink.select('patient.details.responses.loading'),
      responses: sink.select('patient.details.responses.table'),
      count: sink.select('patient.details.responses.count'),
      pageNumber: sink.select('patient.details.responses.page-number'),
      tableMessage: sink.select('patient.details.responses.table-message'),
      hasFilters,
      headers: [
        { value: 'responseDate', text: 'Response Date', sortable: true },
        { value: 'programModule', text: 'Program Module', sortable: true },
        { value: 'question', text: 'Question', sortable: true },
        { value: 'response', text: 'Response', sortable: false },
        { value: 'responseStatus', text: 'Status', sortable: true },
        { value: 'linkIcon', sortable: false },
      ],
      viewChatResponses: item =>
        sink.broadcast(
          PatientDetailsResponsesFilteredFetched({
            // eslint-disable-next-line @typescript-eslint/camelcase
            filters: { question_text: item.question },
            reload: true,
          }),
        ),
      viewAllResponses: () =>
        sink.broadcast(PatientDetailsResponsesFiltersCleared()),
    };
  },
});
