var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{style:({ 'padding-top': _vm.hasFilters ? '24px' : '' })},[(_vm.hasFilters)?_c('v-btn',{staticClass:"btn mb-6",attrs:{"color":"primary"},on:{"click":_vm.viewAllResponses}},[_vm._v(" Show all responses ")]):_vm._e(),_c('InlineLoader',{staticClass:"inline-loader",attrs:{"loading":_vm.loading,"content":"Fetching your results…"}},[_c('v-data-table',{staticClass:"show-headers",attrs:{"must-sort":"","mobile-breakpoint":"1080","headers":_vm.headers,"no-data-text":_vm.tableMessage,"page":_vm.pageNumber,"server-items-length":_vm.count,"sort-by":_vm.sortCol,"sort-desc":_vm.sortDir === 'desc',"items":_vm.responses,"items-per-page":25,"hide-default-footer":!_vm.responses.length,"footer-props":{
        itemsPerPageOptions: [25],
        showCurrentPage: true,
        showFirstLastPage: true,
      }},on:{"update:page":_vm.updatePage,"update:sort-desc":_vm.onChangeSortDirection,"update:sort-by":_vm.onChangeSortColumn},scopedSlots:_vm._u([{key:"item.responseStatus",fn:function(ref){
      var item = ref.item;
return [_c('StatusIndicatorIcon',{attrs:{"status":item.responseStatus}})]}},{key:"item.linkIcon",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"view-responses mb-3",attrs:{"text":"","color":"tertiary"},on:{"click":function($event){return _vm.viewChatResponses(item)}}},[_vm._v(" View all responses ")]),_c('i',{staticClass:"material-icons-outlined icon",attrs:{"title":"View all responses to this question"},on:{"click":function($event){return _vm.viewChatResponses(item)}}},[_vm._v(" message ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }